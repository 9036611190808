import { Image, type StackProps, XStack } from "@medbillai/ui";

export const BackgroundRadialGrantedGradient = (props: StackProps) => {
  return (
    <XStack
      top={0}
      left={0}
      right={0}
      position="absolute"
      width="100%"
      height="100%"
      overflow="hidden"
      zIndex={0}
      {...props}
    >
      <Image
        source={{
          uri: `/static/backgroundGradientFullscreen.png`,
        }}
        height="100%"
        width="100%"
        resizeMode="cover"
        style={{
          objectFit: "cover",
          opacity: 1,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
    </XStack>
  );
};
