import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { AuthLayout } from "@medbillai/app/screens/auth/layout.web";
import { LoginScreen } from "@medbillai/app/screens/auth/login";
import { WebappGate } from "@medbillai/app/screens/webapp-gate";

import { FullscreenSpinner, YStack } from "@medbillai/ui";

// Adjusted import to resolve error
import { type NextPageWithLayout } from "./_app";

const Page: NextPageWithLayout = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (router.isReady) {
      setIsAdmin(router.query.admin === "true");
      setIsLoading(false);
    }
  }, [router.isReady, router.query]);

  return (
    <>
      <Head>
        <title>{isAdmin ? "Loginn" : "Redirect"}</title>
      </Head>
      {isLoading ? (
        <YStack ai="center" jc="center" height="$15">
          <FullscreenSpinner />
        </YStack>
      ) : isAdmin ? (
        <LoginScreen />
      ) : (
        <WebappGate />
      )}
    </>
  );
};

Page.getLayout = page => <AuthLayout>{page}</AuthLayout>;

export default Page;
