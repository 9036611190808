import { type ReactNode } from "react";
import { type TextProps } from "tamagui";

import { Anchor } from "./Typography";

export type ExternalLinkProps = TextProps & {
  href: string;
  title: string;
  target?: string;
  rel?: string;
  children?: ReactNode;
  bold?: boolean;
  isPreviewLink?: boolean;
};

export const ExternalLink = ({
  href = "",
  target = "_blank",
  rel = "noopener noreferrer",
  title,
  children,
  bold = true,
  ...textProps
}: ExternalLinkProps) => {
  const linkProps = { href, target, rel };

  return (
    <Anchor {...linkProps} {...textProps} bold={bold}>
      {children || title}
    </Anchor>
  );
};
